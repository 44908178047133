import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "card" }

import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import { ref, watchEffect } from 'vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerReport',
  setup(__props) {

    const products = ref();
    watchEffect(() => {
        products.value = [
        {
            id: '1000',
            code: 'f230fh0g3',
            name: 'Bamboo Watch',
            description: 'Product Description',
            image: 'bamboo-watch.jpg',
            price: 65,
            category: 'Accessories',
            quantity: 24,
            inventoryStatus: 'INSTOCK',
            rating: 5
        },
        {
            id: '1001',
            code: 'nvklal433',
            name: 'Black Watch',
            description: 'Product Description',
            image: 'black-watch.jpg',
            price: 72,
            category: 'Accessories',
            quantity: 61,
            inventoryStatus: 'INSTOCK',
            rating: 4
        },
        {
            id: '1002',
            code: 'zz21cz3c1',
            name: 'Blue Band',
            description: 'Product Description',
            image: 'blue-band.jpg',
            price: 79,
            category: 'Fitness',
            quantity: 2,
            inventoryStatus: 'LOWSTOCK',
            rating: 3
        }];
    });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(DataTable), {
        value: products.value,
        tableStyle: "min-width: 50rem"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Column), {
            field: "code",
            header: "Code"
          }),
          _createVNode(_unref(Column), {
            field: "name",
            header: "Name"
          }),
          _createVNode(_unref(Column), {
            field: "category",
            header: "Category"
          }),
          _createVNode(_unref(Column), {
            field: "quantity",
            header: "Quantity"
          })
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}
}

})