<template>
    <div class="mt-[32px]">
        <div class="charts">
            <div class="dashboard flex gap-[20px]">
              
                <div v-if="customersOverview" class="grid grid-cols-2 gap-[20px] min-w-[545px]">
                    <div v-for="(customer, index) in customersOverview" :key="index" class="text-center py-[23px] whitespace-nowrap border-[1px] border-solid border-grey-50 rounded-[8px] w-[270px] relative">
                        <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <div class="flex gap-[8px] items-center justify-center">
                                <Icon :style="{color: customer.color}" :icon="customer.icon" width="24" height="24" />
                                <div class="lg:text-body-2 text-caption font-medium text-grey-300">{{ customer.type }}</div>
                            </div>
                            <div :class="['text-caption', {'text-success': customer.percAmount > 0, 'text-error': customer.percAmount < 0 }]">{{ customer.percAmount }}%</div>
                            <div class="mt-[16px] font-semibold lg:text-heading-5 text-body-1">{{ customer.customersNo }}</div>
                        </div>
                    </div>
                </div>

                <div class="border-[1px] border-solid border-grey-50 p-[20px] rounded-[8px] w-[fit-content]">
                    <div class="text-grey-300 lg:text-body-1 text-body-2 font-medium">Store Traffic</div>
                    <div class="lg:text-body-2 text-caption text-grey-200 font-regular">Track the number of customer who visited your store.</div>

                    <div class="dashboard-bar-chart mt-[31px]" v-if="customersOverview?.[1]?.customersNo != 0">
                        <canvas id="barChart" class="bar-chart"></canvas>
                    </div>

                    <div class="dashboard-bar-chart lg:mt-[31px] " v-else>
                        <img src="@/assets/images/images/empty-state.svg" class="mx-[auto]" width="185" height="152" />
                        <div class="text-center text-grey-100 text-body-2 mt-[40px] px-[82px] pb-[20px]">This space will feature a pie chart showcasing your order distribution once data is available.</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, watchEffect,  } from "vue";
    import { formatPrice } from '../../utility/helper/utils.helper'

    const customersOverview = ref([] as any);
    const responseData = {};

    watchEffect( () => {
        [{
                type: 'Total Sales',
                percAmount:  responseData?.totalOrdersAmount?.change || 0,
                customersNo: formatPrice(responseData?.totalOrdersAmount?.value || 0),
                progressPerc: responseData?.totalOrdersAmount?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            }, {
                type: 'Total Orders',
                percAmount:  responseData?.totalOrders?.change || 0,
                customersNo: responseData?.totalOrders?.value || 0,
                progressPerc: responseData?.totalOrders?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            }
           ]
            .forEach((item: any) => {
                customersOverview.value.push({...item})
            })
    })
</script>


<style scoped>

</style>