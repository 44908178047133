import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/images/empty-state.svg'


const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "charts" }
const _hoisted_3 = { class: "dashboard flex gap-[20px]" }
const _hoisted_4 = {
  key: 0,
  class: "grid grid-cols-2 gap-[20px] min-w-[545px]"
}
const _hoisted_5 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_6 = { class: "flex gap-[8px] items-center justify-center" }
const _hoisted_7 = { class: "lg:text-body-2 text-caption font-medium text-grey-300" }
const _hoisted_8 = { class: "mt-[16px] font-semibold lg:text-heading-5 text-body-1" }
const _hoisted_9 = { class: "border-[1px] border-solid border-grey-50 p-[20px] rounded-[8px] w-[fit-content]" }
const _hoisted_10 = {
  key: 0,
  class: "dashboard-bar-chart mt-[31px]"
}
const _hoisted_11 = {
  key: 1,
  class: "dashboard-bar-chart lg:mt-[31px]"
}

import { ref, watchEffect,  } from "vue";
    import { formatPrice } from '../../utility/helper/utils.helper'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'SalesReport',
  setup(__props) {

    const customersOverview = ref([] as any);
    const responseData = {};

    watchEffect( () => {
        [{
                type: 'Total Sales',
                percAmount:  responseData?.totalOrdersAmount?.change || 0,
                customersNo: formatPrice(responseData?.totalOrdersAmount?.value || 0),
                progressPerc: responseData?.totalOrdersAmount?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            }, {
                type: 'Total Orders',
                percAmount:  responseData?.totalOrders?.change || 0,
                customersNo: responseData?.totalOrders?.value || 0,
                progressPerc: responseData?.totalOrders?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            }
           ]
            .forEach((item: any) => {
                customersOverview.value.push({...item})
            })
    })

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (customersOverview.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(customersOverview.value, (customer, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "text-center py-[23px] whitespace-nowrap border-[1px] border-solid border-grey-50 rounded-[8px] w-[270px] relative"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_Icon, {
                        style: _normalizeStyle({color: customer.color}),
                        icon: customer.icon,
                        width: "24",
                        height: "24"
                      }, null, 8, ["style", "icon"]),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(customer.type), 1)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(['text-caption', {'text-success': customer.percAmount > 0, 'text-error': customer.percAmount < 0 }])
                    }, _toDisplayString(customer.percAmount) + "%", 3),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(customer.customersNo), 1)
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-grey-300 lg:text-body-1 text-body-2 font-medium" }, "Store Traffic", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200 font-regular" }, "Track the number of customer who visited your store.", -1)),
          (customersOverview.value?.[1]?.customersNo != 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[0] || (_cache[0] = [
                _createElementVNode("canvas", {
                  id: "barChart",
                  class: "bar-chart"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "mx-[auto]",
                  width: "185",
                  height: "152"
                }, null, -1),
                _createElementVNode("div", { class: "text-center text-grey-100 text-body-2 mt-[40px] px-[82px] pb-[20px]" }, "This space will feature a pie chart showcasing your order distribution once data is available.", -1)
              ])))
        ])
      ])
    ])
  ]))
}
}

})