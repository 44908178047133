<template>
    <AppWrapper activeTab="Reports">
        <div class="">
            <header class="pt-[72px] flex justify-between items-center">
                <div class="">
                    <div class="text-heading-3 font-semibold text-grey-300">Reports and Analysis</div>
                </div>
            </header>
            <main class="">
                <div class="flex gap-[32px] border-b-[1px] border-solid">
                    <div
                        v-for="(tab, index) in settingsTabs"
                        :key="index"
                        :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                        @click="activateTab(index)"
                    > {{ tab }} </div>
                </div>
                <SalesReport v-if="activeTab == 0" />
                <CustomerReport v-if="activeTab == 1" />
            </main>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import SalesReport from './SalesReport.vue';
import CustomerReport from './CustomerReport.vue';
import { ref } from 'vue';

const settingsTabs = ref(
    [
        'Sales', 
        'Customers'
    ]
);
const activeTab = ref(0);
const activateTab = (index: number) => {
    activeTab.value = index;
};

</script>

<style scoped lang="scss">

</style>