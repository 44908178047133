import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex gap-[32px] border-b-[1px] border-solid" }
const _hoisted_4 = ["onClick"]

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import SalesReport from './SalesReport.vue';
import CustomerReport from './CustomerReport.vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReportView',
  setup(__props) {


const settingsTabs = ref(
    [
        'Sales', 
        'Customers'
    ]
);
const activeTab = ref(0);
const activateTab = (index: number) => {
    activeTab.value = index;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Reports" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("header", { class: "pt-[72px] flex justify-between items-center" }, [
          _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "text-heading-3 font-semibold text-grey-300" }, "Reports and Analysis")
          ])
        ], -1)),
        _createElementVNode("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(settingsTabs.value, (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab.value === index }]),
                onClick: ($event: any) => (activateTab(index))
              }, _toDisplayString(tab), 11, _hoisted_4))
            }), 128))
          ]),
          (activeTab.value == 0)
            ? (_openBlock(), _createBlock(SalesReport, { key: 0 }))
            : _createCommentVNode("", true),
          (activeTab.value == 1)
            ? (_openBlock(), _createBlock(CustomerReport, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})